import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/performance';
import 'firebase/analytics';
import { render } from 'react-dom';
import { loadableReady } from '@loadable/component';

const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    render(element, container, callback);
  });
};

export default {
  replaceHydrateFunction,
};
